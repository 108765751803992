@font-face {
  font-family: 'on-document';
  src: url("fonts/styleguide/on-document-groups.eot");
  src: url("fonts/styleguide/on-document-groups.eot#iefix") format("embedded-opentype"), url("fonts/styleguide/on-document-groups.ttf") format("truetype"), url("fonts/styleguide/on-document-groups.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.icon-scissors:before {
  content: "\e900";
}
.icon-link:before {
  content: "\e901";
}
